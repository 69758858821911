// extracted by mini-css-extract-plugin
export var section = "gallery-screen-module--section--3DMdY";
export var grid = "gallery-screen-module--grid--bUGTK";
export var container = "gallery-screen-module--container--r4xOi";
export var slider = "gallery-screen-module--slider--GrxwU";
export var slide = "gallery-screen-module--slide--9LAur";
export var text = "gallery-screen-module--text--WRz9e";
export var top = "gallery-screen-module--top--1+R34";
export var bottom = "gallery-screen-module--bottom--LCPu9";
export var name = "gallery-screen-module--name--497fq";
export var position = "gallery-screen-module--position--RzxCQ";
export var quote = "gallery-screen-module--quote--y6hsz";
export var image = "gallery-screen-module--image--A4t1r";
export var sliderNavContainer = "gallery-screen-module--slider-nav-container--o1VZh";
export var prev = "gallery-screen-module--prev--s5B+q";
export var next = "gallery-screen-module--next--B2cpq";
export var sliderNav = "gallery-screen-module--slider-nav--KxKsw";