import * as React from "react"
import * as css from "./history-screen.module.scss"

export function HistoryCardText(props: { title: string; text?: string }) {
  return (
    <>
      <h3 className={css.cardTitle}>{props.title}</h3>
      {props.text ? <p className={css.cardText} dangerouslySetInnerHTML={{ __html: props.text }}/> : ""}
    </>
  )
}
