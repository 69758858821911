import * as React from "react"
import "./../style/common.scss"
import { CommonLayout } from "../common/common-layout/common-layout"
import { AboutPage } from "../page-components/about/about-page"

export default function About(props) {
  return (
    <CommonLayout {...props}>
      <AboutPage />
    </CommonLayout>
  )
}
