import { IntroScreen } from "./intro-screen/intro-screen"
import { GalleryScreen } from "./gallery-screen/gallery-screen"
import { CompaniesScreen } from "./companies-screen/companies-screen"
import { HistoryScreen } from "./history-screen/history-screen"
import { JoinUsScreen } from "../../common/join-us-screen/join-us-screen"
import { Helmet } from "react-helmet"
import * as React from "react"
import { useTranslation } from "../../common/use-translations/use-translations"
import { useLocale } from "../../common/use-locale/use-locale"
import { getLocalizedUrl } from "../../common/localized-link/localized-link"

export function AboutPage() {
  const _ = useTranslation()
  const { locale } = useLocale()
  const benefitsUrl = getLocalizedUrl("/career/benefits", locale)
  return (
    <>
      <Helmet>
        <title>{_.about_us} - OBRIO</title>
      </Helmet>
      <IntroScreen />
      <GalleryScreen />
      <CompaniesScreen />
      <HistoryScreen />
      <JoinUsScreen
        title={_.join_obrio}
        button={_.want_with_you}
        secondaryButtonUrl={benefitsUrl}
        secondaryButtonName={_.still_hesitating}
        description={_.join_obrio_d}
        ctaClassNames={'about-bottom-cta'}
      />
    </>
  )
}
