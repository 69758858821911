import * as React from "react"
import * as css from "./companies-screen.module.scss"
import { useTranslation } from "../../../common/use-translations/use-translations"

const medias = [
  {
    slug: "kpmg",
  },
  {
    slug: "deloitte",
  },
  {
    slug: "gl",
  },
  {
    slug: "softserve",
  },
  {
    slug: "yalantis",
  },
  {
    slug: "philip",
  },
  {
    slug: "ey",
  },
  {
    slug: "pwc",
  },
  {
    slug: "samsung",
  },
  {
    slug: "vodafone",
  },
  {
    slug: "http",
  },
  {
    slug: "poster",
  },
]

export function CompaniesScreen() {
  const _ = useTranslation()
  return (
    <section className={css.section}>
      <div className="container">
        <h2 className={css.title}>{_.where_team_from}</h2>
        <div className={css.logos}>
          {medias.map((media, index) => (
            <img
              key={index}
              src={`/company/${media.slug}.svg`}
              alt="Company logo"
            />
          ))}
        </div>
      </div>
    </section>
  )
}
