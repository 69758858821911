import { GallerySlide } from "./gallery-slide"

export const gallerySlidesInfoList: GallerySlide[] = [
  {
    title: "alex_fedorov_t",
    description: "alex_fedorov_d",
    name: "alex_fedorov",
    position: "CEO",
    image: "/team/img-slider-fedorov.png",
    imageMob: "/team/img-fedorov.png",
  },
  {
    title: "kateryna_romanenko_t",
    description: "kateryna_romanenko_d",
    name: "kateryna_romanenko",
    position: "Growth Marketing Manager",
    image: "/team/img-slider-romanenko.png",
    imageMob: "/team/img-romanenko.png",
  },
  {
    title: "serhii_matsur_t",
    description: "serhii_matsur_d",
    name: "serhii_matsur",
    position: "Payment Product Manager",
    image: "/team/img-slider-matsur.png",
    imageMob: "/team/img-matsur.png",
  },
  {
    title: "maria_yovsa_t",
    description: "maria_yovsa_d",
    name: "maria_yovsa",
    position: "Head of HR",
    image: "/team/img-slider-yovsa.png",
    imageMob: "/team/img-yovsa.png",
  },
  {
    title: "lidiia_korzun_t",
    description: "lidiia_korzun_d",
    name: "lidiia_korzun",
    position: "Team development specialist",
    image: "/team/img-slider-korzun.png",
    imageMob: "/team/img-korzun.png",
  },
  {
    title: "lesya_nishchenko_t",
    description: "lesya_nishchenko_d",
    name: "lesya_nishchenko",
    position: "Customer Success Team Lead",
    image: "/team/img-slider-nishchenko.png",
    imageMob: "/team/img-nishchenko.png",
  },
  {
    title: "khrystyna_savchuk_t",
    description: "khrystyna_savchuk_d",
    name: "khrystyna_savchuk",
    position: "PR Manager",
    image: "/team/img-slider-savchuk.png",
    imageMob: "/team/img-savchuk.png",
  },
  {
    title: "alex_cholovsky_t",
    description: "alex_cholovsky_d",
    name: "alex_cholovsky",
    position: "Product Manager",
    image: "/team/img-slider-cholovsky.png",
    imageMob: "/team/img-cholovsky.png",
  },
]
