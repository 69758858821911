import * as React from "react"
import * as css from "./history-screen.module.scss"

export function HistoryCardTitle(props: { year: string; label: string }) {
  return (
    <div className={css.cardHeading}>
      <span className={css.year}>{props.year}</span>
      <span className={css.label}>{props.label}</span>
    </div>
  )
}
