import * as React from "react"
import * as css from "./gallery-screen.module.scss"
import { GallerySlide } from "./gallery-slide"
import classNames from "classnames"
import { useTranslation } from "../../../common/use-translations/use-translations"

export const GallerySlideItem = ({ slide }: { slide: GallerySlide }) => {
  const _ = useTranslation()
  return (
    <div className={classNames("container", css.container)}>
      <div className={css.grid}>
        <div className={css.text}>
          <div className={css.top}>
            <img className={css.quote} src="/deco/quote.svg" />
            <span className="uppercase">{_.our_values}</span>
            <h2>{_[slide.title]}</h2>
            <p dangerouslySetInnerHTML={{ __html: _[slide.description]}}></p>
          </div>
          <div className={css.bottom}>
            <div className={css.name}>{_[slide.name]}</div>
            <div className={css.position}>{slide.position}</div>
          </div>
        </div>
        <div className={css.image}>
          <picture>
            <source media="(max-width: 1024px)" srcSet={slide.imageMob} />
            <img src={slide.image} alt="" />
          </picture>
        </div>
      </div>
    </div>
  )
}
