import * as React from "react"
import * as css from "./gallery-screen.module.scss"
import { SwiperSlide, Swiper } from "swiper/react"
import { useState } from "react"
import classNames from "classnames"
import { Icon } from "../../../common/icon/icon"
import { gallerySlidesInfoList } from "./gallery-slides-info-list"
import { GallerySlideItem } from "./gallery-slide-item"

export function GalleryScreen() {
  // @ts-ignore
  const [slider, setSlider] = useState<Swiper>()

  return (
    <section className={css.section}>
      <Swiper
        className={css.slider}
        onInit={swiper => setSlider(swiper)}
        loop={true}
      >
        {gallerySlidesInfoList.map((slide, index) => (
          <SwiperSlide className={css.slide} key={index}>
            <GallerySlideItem slide={slide} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={css.sliderNavContainer}>
        <div className={classNames(css.container, "container")}>
          <button
            className={classNames(css.sliderNav, css.prev)}
            onClick={() => slider.slidePrev()}
          >
            <Icon name="chevron-right" />
          </button>
          <button
            className={classNames(css.sliderNav, css.next)}
            onClick={() => slider.slideNext()}
          >
            <Icon name="chevron-right" />
          </button>
        </div>
      </div>
    </section>
  )
}
