import * as React from "react"
import * as css from "./history-screen.module.scss"
import { TimelineCard } from "../../../common/timeline-card/timeline-card"
import { HistoryCardTitle } from "./history-card-title"
import { HistoryCardText } from "./history-card-text"
import classNames from "classnames"
import { useTranslation } from "../../../common/use-translations/use-translations"

export function HistoryScreen() {
  const _ = useTranslation()
  return (
    <section className={css.section}>
      <h2 className={css.title}>{_.our_history}</h2>

      <div className="container overflow-visible">
        <div className={css.grid}>
          <TimelineCard
            className={classNames(css.card, css.leftC1)}
            direction="left"
            color="violet"
          >
            <>
              <HistoryCardTitle year="2019" label={_.april} />
              <HistoryCardText title={_.april_2019_t} text={_.april_2019_d} />
            </>
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.rightC1)}
            direction="right"
            color="pink"
          >
            <>
              <HistoryCardTitle year="2019" label={_.may} />
              <HistoryCardText title={_.may_2019_t} text={_.may_2019_d} />
            </>
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.leftC2)}
            direction="left"
            color="yellow"
          >
            <>
              <HistoryCardTitle year="2020" label={_.may} />
              <HistoryCardText title={_.may_2020_t} text={_.may_2020_d} />
            </>
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.rightC2)}
            direction="right"
            color="violet"
          >
            <>
              <HistoryCardTitle year="2020" label={_.july} />
              <HistoryCardText title={_.july_2020_t} />
            </>
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.leftC3)}
            direction="left"
            color="pink"
          >
            <>
              <HistoryCardTitle year="2021" label={_.march} />
              <HistoryCardText title={_.march_2021_t} text={_.march_2021_d} />
            </>
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.leftC4)}
            direction="right"
            color="blue"
          >
            <>
              <HistoryCardTitle year="2021" label={_.may} />
              <HistoryCardText title={_.may_2021_t} />
            </>
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.rightC4)}
            direction="left"
            color="violet"
          >
            <>
              <HistoryCardTitle year="2021" label={_.december} />
              <HistoryCardText
                title={_.december_2021_t}
                text={_.december_2021_d}
              />
            </>
          </TimelineCard>

          <TimelineCard
            className={classNames(css.card, css.leftC5)}
            direction="right"
            color="pink"
          >
            <>
              <HistoryCardTitle year="2022" label={_.april} />
              <HistoryCardText
                title={_.april_2022_t}
                text={_.april_2022_d}
              />
            </>
          </TimelineCard>
        </div>
      </div>
    </section>
  )
}
