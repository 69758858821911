// extracted by mini-css-extract-plugin
export var section = "history-screen-module--section--NIw9a";
export var title = "history-screen-module--title--v5xht";
export var cardHeading = "history-screen-module--card-heading--dKDGg";
export var year = "history-screen-module--year--beAQ7";
export var label = "history-screen-module--label--nQqCw";
export var cardTitle = "history-screen-module--card-title--3Rztf";
export var cardText = "history-screen-module--card-text--AGNVx";
export var card = "history-screen-module--card--D93Xd";
export var grid = "history-screen-module--grid--5aOeq";
export var left = "history-screen-module--left--mNtOK";
export var leftC1 = "history-screen-module--left-c-1--mfcR2";
export var leftC2 = "history-screen-module--left-c-2--mg3qa";
export var leftC3 = "history-screen-module--left-c-3--WvEmx";
export var leftC4 = "history-screen-module--left-c-4--czbZ2";
export var leftC5 = "history-screen-module--left-c-5--xwZ6P";
export var right = "history-screen-module--right--4pMVw";
export var rightC1 = "history-screen-module--right-c-1--kruXk";
export var rightC2 = "history-screen-module--right-c-2--Od2Wa";
export var rightC3 = "history-screen-module--right-c-3--XnN1u";
export var rightC4 = "history-screen-module--right-c-4--VFtFz";
export var rightC5 = "history-screen-module--right-c-5--P1TJ2";