import * as React from "react"
import * as css from "./intro-screen.module.scss"
import { useTranslation } from "../../../common/use-translations/use-translations"
import { useEffect, useState } from "react"

export function IntroScreen() {
  const _ = useTranslation()
  const [showVideo, setShowVideo] = useState(false)

  useEffect(() => {
    setShowVideo(typeof window !== "undefined" && window.innerWidth > 768)
  })

  return (
    <section className={css.section}>
      <div className="container">
        <div className={css.grid}>
          {showVideo ? (
            <video
              className={css.media}
              src="/OBRIO_720.mp4"
              controls={false}
              autoPlay={true}
              loop={true}
              muted={true}
            />
          ) : null}
          <div className={css.text}>
            <h1>{_.about_h1}</h1>
            <p>{_.about_description}</p>
          </div>
        </div>
      </div>
    </section>
  )
}
